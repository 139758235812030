import React from "react";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";

import Card from "../common/card";

import "./styles/works.css";

const Works = () => {
	return (
		<div className="works">
			<Card
				icon={faBriefcase}
				title="Work Experience"
				body={
					<div className="works-body">
						<div className="work">
							<img
								src="https://media.licdn.com/dms/image/D4D0BAQHWXf1_fFcOCw/company-logo_200_200/0/1703773736245/hayman_woodward_logo?e=1712188800&v=beta&t=5GxB0FfWNjK98q31ZguTDkZo74dYW5NnrU3sgb5AZHA"
								alt="hayman woodward"
								className="work-image"
							/>
							<div className="work-title">Hayman Woodward</div>
							<div className="work-subtitle">
								Frontend Software Engineer
							</div>
							<div className="work-duration">2023 - Present</div>
						</div>
						<div className="work">
							<img
								src="https://media.licdn.com/dms/image/D560BAQHz8KWhZN-qlw/company-logo_200_200/0/1695325767366/linkeen_logo?e=1712188800&v=beta&t=6H7CeqqA3T1sO8YgbszBvOjmMHv25tCnmLsf3Hhxn58"
								alt="linkeen"
								className="work-image"
							/>
							<div className="work-title">
								Linkeen Technologies
							</div>
							<div className="work-subtitle">
								Frontend Software Engineer
							</div>
							<div className="work-duration">2023 - Present</div>
						</div>
						<div className="work">
							<img
								src="https://hmu787.p3cdn1.secureserver.net/wp-content/uploads/2023/04/favicon_sg-300x300.png"
								alt="stargaze games"
								className="work-image"
							/>
							<div className="work-title">Stargaze Games</div>
							<div className="work-subtitle">
								Frontend Software Engineer
							</div>
							<div className="work-duration">2023 - 2023</div>
						</div>
						<div className="work">
							<img
								src="https://media.licdn.com/dms/image/C4D0BAQFZWLAFEOhH3Q/company-logo_200_200/0/1632513981925/descomplica_logo?e=1712188800&v=beta&t=PtpbH7KeyI0mJHwCAbhMMc1yDKZWlpvgElg0tofsGXM"
								alt="descomplica"
								className="work-image"
							/>
							<div className="work-title">Descomplica</div>
							<div className="work-subtitle">
								Software Engineer
							</div>
							<div className="work-duration">2021 - 2023</div>
						</div>
						<div className="work">
							<img
								src="https://www.pinoylisting.com/wp-content/uploads/2023/08/placeholder-company-30f7247c043cac59226403f669f2afea709784b1f52cc405c90672c3d9ec428a.jpg"
								alt="graca multimidia"
								className="work-image"
							/>
							<div className="work-title">Graca Multimidia</div>
							<div className="work-subtitle">
								Trainee & Jr. Software Engineer
							</div>
							<div className="work-duration">2017 - 2021</div>
						</div>
					</div>
				}
			/>
		</div>
	);
};

export default Works;
