import React from "react";

function article_1() {
	return {
		date: "30 Dec 2023",
		title: "How to Become a Front End Developer",
		description:
			"It is important to understand that you do not need to know everything before working as a front-end developer, but the fundamentals, such as HTML, CSS, and JavaScript, are always essential.",
		keywords: ["frontend developer"],
		style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				`,
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">Content of article 1</div>
				</div>
			</React.Fragment>
		),
	};
}

function article_2() {
	return {
		date: "30 Dec 2023",
		title: "How to Become a Back End Developer",
		description:
			"A back-end developer works on the server, database and APIs behind a web application. It's important to know the fundamentals, such as Hyper Text Transfer Protocol (HTTP), software testing, database indexing and persistence, software engineering principles, asynchronous vs synchronous programming, and load balancing.",
		keywords: ["frontend developer"],
		style: `
				.article-content {
					display: flex;
					flex-direction: column;
					align-items: center;
				}

				.randImage {
					align-self: center;
					outline: 2px solid red;
				}
				`,
		body: (
			<React.Fragment>
				<div className="article-content">
					<div className="paragraph">Content of article 2</div>
				</div>
			</React.Fragment>
		),
	};
}

const myArticles = [article_1, article_2];

export default myArticles;
